import {
  createRouter,
  createWebHistory,
  createWebHashHistory,
} from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    redirect: '/home',
  },
  {
    path: '/folder/:id',
    component: () => import('../views/Folder.vue'),
  },
  {
    path: '/home',
    component: () => import('../views/Home.vue'),
  },
  {
    path: '/notiziario',
    component: () => import('../views/Notiziario.vue'),
  },

  {
    path: '/webradio',
    component: () => import('../views/WebRadio.vue'),
  },

  {
    path: '/catechismo',
    component: () => import('../views/Catechismo.vue'),
  },

  {
    path: '/patronato',
    component: () => import('../views/Patronato.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
