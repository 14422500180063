
import {
  IonApp,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
  IonRouterOutlet,
  IonSplitPane,
} from "@ionic/vue";
import { defineComponent, ref } from "vue";
import { useRoute } from "vue-router";
import {
  archiveOutline,
  archiveSharp,
  bookmarkOutline,
  bookmarkSharp,
  heartOutline,
  heartSharp,
  mailOutline,
  mailSharp,
  paperPlaneOutline,
  paperPlaneSharp,
  trashOutline,
  trashSharp,
  warningOutline,
  warningSharp,
  documentOutline,
  documentSharp,
  radioOutline,
  radioSharp,
  homeOutline,
  homeSharp,
} from "ionicons/icons";

export default defineComponent({
  name: "App",
  components: {
    IonApp,
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonMenu,
    IonMenuToggle,
    IonNote,
    IonRouterOutlet,
    IonSplitPane,
  },
  setup() {
    const selectedIndex = ref(0);
    const appPages = [
      {
        title: "Home",
        url: "/home",
        iosIcon: homeOutline,
        mdIcon: homeSharp,
      },
      {
        title: "Notiziario",
        url: "/notiziario",
        iosIcon: documentOutline,
        mdIcon: documentSharp,
      },
      {
        title: "Catechismo",
        url: "/catechismo",
        iosIcon: paperPlaneOutline,
        mdIcon: paperPlaneSharp,
      },
      {
        title: "Patronato",
        url: "/patronato",
        iosIcon: paperPlaneOutline,
        mdIcon: paperPlaneSharp,
      },
      // {
      //   title: "Giovani",
      //   url: "/folder/Giovani",
      //   iosIcon: heartOutline,
      //   mdIcon: heartSharp,
      // },
      {
        title: "Avvisi",
        url: "/folder/Avvisi",
        iosIcon: archiveOutline,
        mdIcon: archiveSharp,
      },
      {
        title: "Radio",
        url: "/#webradio",
        iosIcon: radioOutline,
        mdIcon: radioSharp,
      },
      // {
      //   title: "Spam",
      //   url: "/folder/Spam",
      //   iosIcon: warningOutline,
      //   mdIcon: warningSharp,
      // },
    ];
    const labels = [
      "Family",
      "Friends",
      "Notes",
      "Work",
      "Travel",
      "Reminders",
    ];

    const path = window.location.pathname.split("folder/")[1];
    if (path !== undefined) {
      selectedIndex.value = appPages.findIndex(
        (page) => page.title.toLowerCase() === path.toLowerCase()
      );
    }

    const route = useRoute();

    return {
      selectedIndex,
      appPages,
      labels,
      archiveOutline,
      archiveSharp,
      bookmarkOutline,
      bookmarkSharp,
      heartOutline,
      heartSharp,
      mailOutline,
      mailSharp,
      paperPlaneOutline,
      paperPlaneSharp,
      trashOutline,
      trashSharp,
      warningOutline,
      warningSharp,
      isSelected: (url: string) => (url === route.path ? "selected" : ""),
    };
  },
});
